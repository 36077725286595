import * as React from 'react';
import { Formik, Form, Field } from 'formik';
import styled, { css } from 'styled-components';
import type { ReactNode } from 'react';
import { useState, useEffect } from 'react';

import type { DateTime, DateTimeFormats } from 'util/DateTime';
import { isValidDate } from 'util/DateTime';
import TimeRangeSelectInput from 'data-warehouse/components/TimeRangeSelectInput';
import type { EstimateParams } from 'data-warehouse/hooks/useDataWarehouseEstimate';
import useDataWarehouseEstimate from 'data-warehouse/hooks/useDataWarehouseEstimate';
import ArchiveSizeEstimate from 'data-warehouse/components/ArchiveSizeEstimate';
import { Modal, Input } from 'components/bootstrap';
import { FormikInput, ModalSubmit, Select } from 'components/common';
import useUserDateTime from 'hooks/useUserDateTime';
import TimeRangeDisplay from 'views/components/searchbar/time-range-filter/TimeRangeDisplay';
import type { AbsoluteTimeRange } from 'views/logic/queries/Query';

import { BOTH_INCLUSION_TYPE, DATA_WAREHOUSE_INCLUSION_TYPE, INDEXER_INCLUSION_TYPE } from '../Constants';

export type DataWarehouseActionFormValues = {
  from: string,
  to: string,
  full_delete?: boolean,
  wipe_restores?: boolean,
  inclusion_type?: Pick<EstimateParams, 'inclusion_type'>,
}

type Props = {
  modalTitle: string,
  defaultTimeRange?: AbsoluteTimeRange,
  show: boolean,
  onHide: () => void
  submitButtonText: string,
  children: React.ReactNode,
  onSubmit: (values: DataWarehouseActionFormValues) => void,
  hourOnly?: boolean,
  streamIds: Array<string>,
  type: 'retrieval' | 'delete'
};
type FormatTime =(time: DateTime, format?: DateTimeFormats) => string;

const StyledModal = styled(Modal)`
  z-index: 1070;
`;

const StyledP = styled.p`
  margin-top: 10px;
  font-weight: bold;
`;

const FullDelete = styled.div(({ theme }) => css`
  display: flex;
  gap: ${theme.spacings.lg};
  margin-top: ${theme.spacings.lg};
`);

const ErrorMessage = styled.span(({ theme }) => css`
  color: ${theme.colors.variant.dark.danger};
  font-size: ${theme.fonts.size.small};
  font-style: italic;
  padding: ${theme.spacings.xs};
  height: 1.5em;
  display: block;
`);
const INCLUSION_TYPE_OPTIONS = [
  { value: DATA_WAREHOUSE_INCLUSION_TYPE, label: 'Must include: Search Cluster' },
  { value: INDEXER_INCLUSION_TYPE, label: 'Must exclude: Search Cluster' },
  { value: BOTH_INCLUSION_TYPE, label: 'Include All' },
];

const getDefaultValues = (formatTime: FormatTime, isDeleteAction: boolean, timeRange?: AbsoluteTimeRange) => {
  const from = timeRange?.from || new Date().setMinutes(0, 0);
  const to = timeRange?.to || new Date().setMinutes(0, 0);

  return {
    ...(!isDeleteAction && { inclusion_type: BOTH_INCLUSION_TYPE }),
    nextTimeRange: {
      type: 'absolute',
      from: formatTime(from),
      to: formatTime(to),
    },
  };
};

const DataWarehouseActionModalForm = ({
  modalTitle,
  defaultTimeRange,
  show,
  onSubmit,
  onHide,
  children,
  submitButtonText,
  hourOnly,
  streamIds,
  type,
}: Props) => {
  const [estimateParam, setEstimateParam] = useState<EstimateParams>({
    from: defaultTimeRange?.from || '',
    to: defaultTimeRange?.to || '',
    streamIds: streamIds || undefined,
    inclusion_type: undefined,
  });
  const {
    data: estimate,
    refetch: refetchEstimate,
  } = useDataWarehouseEstimate(estimateParam);

  const { formatTime } = useUserDateTime();
  const formatError = 'Format must be: YYYY-MM-DD [HH:mm].';
  const rangeError = 'The "To" date must come after the "From" date.';

  useEffect(() => {
    refetchEstimate();
  }, [refetchEstimate, estimateParam]);

  const isDeleteAction = type === 'delete';

  const validate = (values) => {
    const { nextTimeRange: { to, from }, full_delete, inclusion_type } = values;
    setEstimateParam({ streamIds, from, to, inclusion_type });
    let errors: {
      from?: string,
      to?: string,
      inclusion_type?: string,
    } = {};

    if (!isDeleteAction && !inclusion_type) {
      errors = { ...errors, inclusion_type: 'The Include Message field is required.' };
    }

    if (!full_delete && !from) {
      errors = { ...errors, from: 'The "From" field is required.' };
    }

    if (!full_delete && !to) {
      errors = { ...errors, to: 'The "To" field is required.' };
    }

    if (!full_delete && from && !isValidDate(from)) {
      errors = { ...errors, from: formatError };
    }

    if (!full_delete && to && !isValidDate(to)) {
      errors = { ...errors, to: formatError };
    }

    if (!full_delete && from >= to) {
      errors = { ...errors, to: rangeError };
    }

    return errors;
  };

  const handleSubmit = ({ nextTimeRange: { from, to }, full_delete, wipe_restores, inclusion_type }) => {
    onSubmit({ from, to, full_delete, wipe_restores, inclusion_type });
  };

  return (
    <StyledModal title={modalTitle}
                 onHide={() => onHide()}
                 show={show}>

      <Formik initialValues={getDefaultValues(formatTime, isDeleteAction, defaultTimeRange)}
              onSubmit={handleSubmit}
              validate={validate}>
        {({ isSubmitting, isValidating, isValid, setFieldValue, values: { full_delete, nextTimeRange, inclusion_type }, errors }) => (
          <Form>
            <Modal.Header closeButton>
              <Modal.Title>{modalTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {children}

              {isDeleteAction && (
              <FullDelete>
                <Input id="full_delete"
                       label="Full Delete:">
                  <FormikInput label="Delete all data in the data warehouse"
                               name="full_delete"
                               id="full_delete"
                               type="checkbox" />
                </Input>
                {full_delete && (
                <Input id="wipe_restores"
                       label="Wipe retrievals:">
                  <FormikInput label="Wipe all retrievals"
                               name="wipe_restores"
                               id="wipe_restores"
                               disabled={!full_delete}
                               type="checkbox" />
                </Input>
                )}
              </FullDelete>
              )}
              {!full_delete && (
              <>
                <StyledP>Time Range: </StyledP>
                <p>Please select a time range and click {type}.</p>
                <TimeRangeDisplay timerange={nextTimeRange} />
                <TimeRangeSelectInput timerange={nextTimeRange}
                                      disableTimeMinute={hourOnly}
                                      disableTimeSecond={hourOnly} />
              </>

              )}
              {!isDeleteAction && (
              <Field name="inclusion_type">
                {() => (
                  <Input help="Type of message to be retreive"
                         id="inclusion-type"
                         label="Filter Retrieval by Original Destination">
                    <Select id="inclusionType"
                            name="inclusion_type"
                            placeholder="Select type of retrieval filter"
                            options={INCLUSION_TYPE_OPTIONS}
                            matchProp="label"
                            onChange={(option) => {
                              setFieldValue('inclusion_type', option);
                            }}
                            value={inclusion_type} />
                  </Input>
                )}
              </Field>
              )}

              {errors && (
                Object.keys(errors).map((value) => (
                  <ErrorMessage>{errors[value] as ReactNode} </ErrorMessage>
                ))
              )}
              {(!full_delete && !errors.to && !errors.from && !errors.inclusion_type) && estimate && <ArchiveSizeEstimate estimate={estimate} />}
            </Modal.Body>
            <Modal.Footer>
              <ModalSubmit submitButtonText={submitButtonText}
                           submitLoadingText="Submitting request..."
                           onCancel={() => onHide()}
                           disabledSubmit={isValidating || !isValid}
                           isSubmitting={isSubmitting} />
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </StyledModal>
  );
};

DataWarehouseActionModalForm.defaultProps = {
  hourOnly: false,
  defaultTimeRange: undefined,
};

export default DataWarehouseActionModalForm;
