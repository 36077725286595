const gmailRoutes = {
  INTEGRATIONS: {
    Gmail: {
      ACTIVITYAPI: {
        index: '/integrations/gmail',
      },
    },
  },
};

const ApiRoutes = {
  INTEGRATIONS: {
    Gmail: {
      SAVE_INPUT: '/plugins/org.graylog.enterprise.integrations/gmail/inputs',
      CHECK_CREDENTIALS: '/plugins/org.graylog.enterprise.integrations/gmail/checkCredentials',
    },
  },
};

const DocsRoutes = {
  INTEGRATIONS: {
    Gmail: {
      GRAYLOG_Gmail_ACTIVITY_LOG_INPUT: 'integrations/inputs/graylog_gmail_ActivityLog_Input.html#graylog-gmail-activitylog-input',
    },
  },
};

export default gmailRoutes;

export { DocsRoutes, ApiRoutes };
