import * as React from 'react';

import PageNavigation from 'components/common/PageNavigation';
import { DATA_WAREHOUSE_ROUTES } from 'data-warehouse/Constants';
import { Row } from 'components/bootstrap';

const DataWarehousePageNavigation = () => {
  const NAV_ITEMS = [
    { title: 'Overview', path: DATA_WAREHOUSE_ROUTES.ARCHIVE.LIST, exactPathMatch: true },
    { title: 'Backend', path: DATA_WAREHOUSE_ROUTES.BACKEND, exactPathMatch: true },
    { title: 'Configuration', path: DATA_WAREHOUSE_ROUTES.CONFIGURATION, exactPathMatch: true },
  ];

  return (
    <Row>
      <PageNavigation items={NAV_ITEMS} />
    </Row>
  );
};

export default DataWarehousePageNavigation;
