interface Route<T> {
  INTEGRATIONS: {
    Defender: {
      [key: string]: T
    },
  },
}

const DefenderRoutes: Route<{ index: string }> = {
  INTEGRATIONS: {
    Defender: {
      ACTIVITYAPI: {
        index: '/integrations/microsoftdefender',
      },
    },
  },
};

const ApiRoutes: Route<string> = {
  INTEGRATIONS: {
    Defender: {
      SAVE_INPUT: '/plugins/org.graylog.enterprise.integrations/microsoftdefender/inputs',
      CHECK_CREDENTIALS: '/plugins/org.graylog.enterprise.integrations/microsoftdefender/checkCredentials',

    },
  },
};

const DocsRoutes: Route<string> = {
  INTEGRATIONS: {
    Defender: {
      GRAYLOG_Defender_ACTIVITY_LOG_INPUT: 'integrations/inputs/graylog_defender_ActivityLog_Input.html#graylog-defender-activitylog-input',
    },
  },
};

export default DefenderRoutes;

export { DocsRoutes, ApiRoutes };
