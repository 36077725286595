import PropTypes from 'prop-types';
import { useEffect } from 'react';

import useHistory from 'routing/useHistory';

import gmailRoutes from './common/Routes.js';

// https://stackoverflow.com/questions/52912238/render-methods-should-be-a-pure-function-of-props-and-state
const GmailInputConfiguration = ({ url }) => {
  const history = useHistory();

  useEffect(() => {
    history.push(url);
  }, [history, url]);

  return null;
};

GmailInputConfiguration.propTypes = {
  url: PropTypes.string,
};

GmailInputConfiguration.defaultProps = {
  url: gmailRoutes.INTEGRATIONS.Gmail.ACTIVITYAPI.index,
};

export default GmailInputConfiguration;
