interface Route<T> {
  INTEGRATIONS: {
    AWSSecurityLake: {
      [key: string]: T
    },
  },
}

const AWSSecurityLakeRoutes: Route<{ index: string }> = {
  INTEGRATIONS: {
    AWSSecurityLake: {
      ACTIVITYAPI: {
        index: '/integrations/awssecuritylake',
      },
    },
  },
};

const ApiRoutes: Route<string> = {
  INTEGRATIONS: {
    AWSSecurityLake: {
      SAVE_INPUT: '/plugins/org.graylog.enterprise.integrations/awssecuritylake/inputs',
      CHECK_CREDENTIALS: '/plugins/org.graylog.enterprise.integrations/awssecuritylake/checkCredentials',
      GET_AWS_REGIONS: '/plugins/org.graylog.enterprise.integrations/awssecuritylake/getawsregions',

    },
  },
};

const DocsRoutes: Route<string> = {
  INTEGRATIONS: {
    AWSSecurityLake: {
      GRAYLOG_AWSSecurityLake_ACTIVITY_LOG_INPUT: 'integrations/inputs/graylog_AWSSecurityLake_ActivityLog_Input.html#graylog-AWSSecurityLake-activitylog-input',
    },
  },
};

export default AWSSecurityLakeRoutes;

export { DocsRoutes, ApiRoutes };
