const workspaceV2Routes = {
  INTEGRATIONS: {
    WorkspaceV2: {
      ACTIVITYAPI: {
        index: '/integrations/google_workspace_v2',
      },
    },
  },
};

const ApiRoutes = {
  INTEGRATIONS: {
    WorkspaceV2: {
      SAVE_INPUT: '/plugins/org.graylog.enterprise.integrations/google_workspace_v2/inputs',
      CHECK_CREDENTIALS: '/plugins/org.graylog.enterprise.integrations/google_workspace_v2/check_credentials',
    },
  },
};

const DocsRoutes = {
  INTEGRATIONS: {
    WorkspaceV2: {
      GRAYLOG_WorkspaceV2_ACTIVITY_LOG_INPUT: 'integrations/inputs/graylog_workspacev2_ActivityLog_Input.html#graylog-workspacev2-activitylog-input',
    },
  },
};

export default workspaceV2Routes;

export { DocsRoutes, ApiRoutes };
