import OidcConfiguration from './OidcConfiguration';
import OidcLogin from './OidcLogin';
import OidcCallbackPage from './OidcCallbackPage';

export default {
  routes: [
    { path: '/authorization-code/callback', component: OidcCallbackPage },
  ],
  authenticatorConfigurations: [
    {
      name: 'OIDC',
      displayName: 'OpenID Connect',
      description: 'Authenticates users via OpenID Connect.',
      canBeDisabled: false,
      component: OidcConfiguration,
    },
  ],
  loginProviderType: [
    {
      type: 'oidc-v1',
      formComponent: OidcLogin,
    },
  ],
};
