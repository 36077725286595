interface Route<T> {
  INTEGRATIONS: {
    Salesforce: {
      [key: string]: T
    },
  },
}

const SalesforceRoutes: Route<{ index: string }> = {
  INTEGRATIONS: {
    Salesforce: {
      ACTIVITYAPI: {
        index: '/integrations/salesforce',
      },
    },
  },
};

const ApiRoutes: Route<string> = {
  INTEGRATIONS: {
    Salesforce: {
      SAVE_INPUT: '/plugins/org.graylog.enterprise.integrations/salesforce/inputs',
      CHECK_CREDENTIALS: '/plugins/org.graylog.enterprise.integrations/salesforce/checkCredentials',
      GET_LOG_TYPES: '/plugins/org.graylog.enterprise.integrations/salesforce/getLogTypes',
    },
  },
};

const DocsRoutes: Route<string> = {
  INTEGRATIONS: {
    Salesforce: {
      GRAYLOG_Salesforce_ACTIVITY_LOG_INPUT: 'integrations/inputs/graylog_Salesforce_ActivityLog_Input.html#graylog-Salesforce-activitylog-input',
    },
  },
};

export default SalesforceRoutes;

export { DocsRoutes, ApiRoutes };
