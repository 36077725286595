import * as React from 'react';
import { useCallback, useState } from 'react';

import { qualifyUrl } from 'util/URLUtils';
import UserNotification from 'util/UserNotification';
import type { DataWarehouseActionFormValues } from 'data-warehouse/components/DataWarehouseActionModalForm';
import DataWarehouseActionModalForm from 'data-warehouse/components/DataWarehouseActionModalForm';
import { DATA_WAREHOUSE_API_ROUTES, DATA_WAREHOUSE_ROUTES, HIDE_DATAWAREHOUSE_RETRIEVAL_HELP_TEXT_KEY } from 'data-warehouse/Constants';
import { getDataWarehouseUriParams } from 'data-warehouse/logic/Utils';
import Store from 'logic/local-storage/Store';
import fetch from 'logic/rest/FetchProvider';
import { Alert, Button } from 'components/bootstrap';
import { Link } from 'components/common/router';
import useUserDateTime from 'hooks/useUserDateTime';
import type { AbsoluteTimeRange } from 'views/logic/queries/Query';
import { ConfirmDialog } from 'components/common';

type Props = {
  streamIds: Array<string>,
  timeRange?: AbsoluteTimeRange,
};

const DataWarehouseSearchRetreive = ({ streamIds, timeRange }: Props) => {
  const [showRetrievalModal, setShowRetrievalModal] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [hideHelpText, setHideHelpText] = useState(Store.get(HIDE_DATAWAREHOUSE_RETRIEVAL_HELP_TEXT_KEY) === 'true');
  const { userTimezone } = useUserDateTime();

  const hideRetrievalHelp = () => {
    Store.set(HIDE_DATAWAREHOUSE_RETRIEVAL_HELP_TEXT_KEY, 'true');
    setHideHelpText(true);
  };

  const onRetrieveSubmit = useCallback((values: DataWarehouseActionFormValues) => {
    const uriParams = getDataWarehouseUriParams(values, userTimezone);

    fetch(
      'POST',
      qualifyUrl(`${DATA_WAREHOUSE_API_ROUTES.DataWarehouseAPIController.bulk_retrieval().url}?${uriParams}`),
      { stream_ids: streamIds, inclusion_type: values.inclusion_type },
    ).then(() => {
      UserNotification.success('Data warehouse retreive jobs was successfully submitted.', 'Success');
      setShowInfoModal(true);
    }).catch((error) => {
      UserNotification.error(`An error occurred while submitting retrieval job. ${error}`);
    }).finally(() => {
      setShowRetrievalModal(false);
    });
  }, [streamIds, userTimezone]);

  return (

    <>
      <Button bsStyle="link"
              onClick={() => setShowRetrievalModal(true)}>Click to retrieve
      </Button>
      {showRetrievalModal && (
      <DataWarehouseActionModalForm modalTitle="Restore Data Warehouse data"
                                    defaultTimeRange={timeRange}
                                    type="retrieval"
                                    show={showRetrievalModal}
                                    onHide={() => setShowRetrievalModal(false)}
                                    submitButtonText="Retrieve"
                                    streamIds={streamIds}
                                    onSubmit={onRetrieveSubmit}>
        <p>You are about to retrieve {streamIds.length} streams.</p>
        {!hideHelpText && (
        <Alert bsStyle="info" onDismiss={hideRetrievalHelp}>
          This will launch a retrieval operation to route messages from the Data Warehouse into the <b>Search Cluster</b>.<br />
          Retrieved messages that were not previously routed to the <b>Search Cluster </b> will count towards <b>Graylog License</b> usage.<br />
          Note that pulling messages from the Data Warehouse may take some time - progress may be tracked on
          the <Link to={DATA_WAREHOUSE_ROUTES.ARCHIVE.LIST}><b>Data Warehouse</b></Link> page.
        </Alert>
        )}
      </DataWarehouseActionModalForm>
      )}
      {showInfoModal && (
      <ConfirmDialog show
                     onConfirm={() => setShowInfoModal(false)}
                     hideCancelButton
                     title="Data warehouse retrieval"
                     btnConfirmText="Ok">
        Retreival jobs for the selected stream was submitted successfully. Please check the progress <Link to={DATA_WAREHOUSE_ROUTES.ARCHIVE.LIST}>Data Warehouse overview page</Link> .
        Once the job is done the retrieved messages will be available on search results.
      </ConfirmDialog>
      )}
    </>
  );
};

DataWarehouseSearchRetreive.defaultProps = {
  timeRange: undefined,
};

export default DataWarehouseSearchRetreive;
