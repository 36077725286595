interface Route<T> {
  INTEGRATIONS: {
    GenericS3: {
      [key: string]: T
    },
  },
}

const GenericS3Routes: Route<{ index: string }> = {
  INTEGRATIONS: {
    GenericS3: {
      ACTIVITYAPI: {
        index: '/integrations/generics3',
      },
    },
  },
};

const ApiRoutes: Route<string> = {
  INTEGRATIONS: {
    GenericS3: {
      SAVE_INPUT: '/plugins/org.graylog.enterprise.integrations/generics3/inputs',
      CHECK_CREDENTIALS: '/plugins/org.graylog.enterprise.integrations/generics3/checkCredentials',
      GET_AWS_REGIONS: '/plugins/org.graylog.enterprise.integrations/generics3/getawsregions',

    },
  },
};

const DocsRoutes: Route<string> = {
  INTEGRATIONS: {
    GenericS3: {
      GRAYLOG_GenericS3_ACTIVITY_LOG_INPUT: 'integrations/inputs/graylog_generic_s3_ActivityLog_Input.html#graylog-genericS3-activitylog-input',
    },
  },
};

export default GenericS3Routes;

export { DocsRoutes, ApiRoutes };
