import PropTypes from 'prop-types';
import { useEffect } from 'react';

import useHistory from 'routing/useHistory';

import Routes from './common/Routes.js';

// https://stackoverflow.com/questions/52912238/render-methods-should-be-a-pure-function-of-props-and-state
const O365InputConfiguration = ({ url }) => {
  const history = useHistory();

  useEffect(() => {
    history.push(url);
  }, [history, url]);

  return null;
};

O365InputConfiguration.propTypes = {
  url: PropTypes.string,
};

O365InputConfiguration.defaultProps = {
  url: Routes.INTEGRATIONS.O365.ACTIVITYAPI.index,
};

export default O365InputConfiguration;
