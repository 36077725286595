const workspaceRoutes = {
  INTEGRATIONS: {
    Workspace: {
      ACTIVITYAPI: {
        index: '/integrations/googleworkspace',
      },
    },
  },
};

const ApiRoutes = {
  INTEGRATIONS: {
    Workspace: {
      SAVE_INPUT: '/plugins/org.graylog.enterprise.integrations/googleworkspace/inputs',
    },
  },
};

const DocsRoutes = {
  INTEGRATIONS: {
    Workspace: {
      GRAYLOG_Workspace_ACTIVITY_LOG_INPUT: 'integrations/inputs/graylog_workspace_ActivityLog_Input.html#graylog-workspace-activitylog-input',
    },
  },
};

export default workspaceRoutes;

export { DocsRoutes, ApiRoutes };
